.step-tracker {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 3rem 0 0;
  counter-reset: count;
  justify-content: space-between;
  li {
    display: flex;
    align-items: center;
    margin-right: 23px;
    span {
      font-family: 'Poppins', sans-serif;
      font-size: 1.2rem;
    }
    &::before {
      content: counter(count);
      counter-increment: count;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #59b6d2;
      color: #fff;
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      min-width: 2.6rem;
      max-width: 2.6rem;
      max-height: 2.6rem;
      min-height: 2.6rem;
      border-radius: 100%;
      font-size: 1.8rem;
      margin-right: 2rem;
      line-height: initial;
    }
    &.active {
      &::before {
        background: $ywDigitalBlue;
      }
      span {
        color: $ywDigitalBlue;
      }
    }
    &.success-checkmark:before {
      content: '';
      display: flex;
      text-align: center;
      justify-content: center;
      min-width: 2.6rem;
      max-width: 2.6rem;
      max-height: 2.6rem;
      min-height: 2.6rem;
      color: #fff;
      font-size: 18px;
      background: #32ba7c;
      border-radius: 50%;
      background-image: url('../../images/form-complete-tick.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  @media screen and (min-width: 992px) {
    li {
      span {
        font-size: 1.8rem;
      }
      &::before {
        font-size: 2.8rem;
        min-width: 4.6rem;
        max-width: 4.6rem;
        max-height: 4.6rem;
        min-height: 4.6rem;
      }
      &.success-checkmark::before {
        font-size: 2.8rem;
        min-width: 4.6rem;
        max-width: 4.6rem;
        max-height: 4.6rem;
        min-height: 4.6rem;
      }
    }
  }
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    li {
      &::before {
        margin-right: 0.5rem;
      }
      span {
        display: none;
      }
      &.active {
        span {
          display: block;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    li {
      font-size: 1.4rem;
      &::before {
        margin-right: 1rem;
      }
    }
  }
  @media screen and (max-width: 480px) {
    li {
      margin-right: 12px;
    }
  }
}
