.hero-layout {
  position: relative;
  display: flex;
  justify-content: center;
  .c-main {
    min-height: 30rem !important;
    @media screen and (min-width: 991px) {
      padding: 3.5rem 9.5rem 2rem !important;
    }
    @media screen and (max-width: 992px) {
      // padding-left: 3.5rem !important;
      // padding-right: 3.5rem !important;
      // padding-top: 2rem !important;
      padding: 2rem !important;
    }
    .c-box {
      background-color: white;
    }
  }
  .home-page {
    .c-main {
      background-color: transparent !important;
    }
    @media screen and (max-width: 992px) {
      .c-main {
        padding: 0 !important;
        .c-box-home {
          .home-detail-wrapper {
            padding: 2rem 3rem;
            background-color: #131c42;
          }
          .manage-property {
            padding-left: 3rem;
            padding-right: 3rem;
          }
        }
      }
    }
  }

  .o-container {
    @media screen and (max-width: 992px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      .c-main-home {
        padding: 2rem 3.5rem !important;
      }
    }
  }
  .hero-banner {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 30.8rem;
    top: 0;
    left: 0;
    background-color: #111b45;
    background-image: url(../../images/hero-collapsed.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .hero-banner-collapsed {
    margin: 13rem 0 0;
    position: relative;
    @media screen and (max-width: 1280px) {
      margin-left: 2rem;
      margin-right: 2rem;
      width: auto;
    }
    @media screen and (max-width: 992px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
