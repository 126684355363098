@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import 'node_modules/yw-ui.v2/dist/scss/01_settings/colours';
@import './components/hero-layout';
@import './components/step-tracker';
@import './components/your-address';
@import './components/you-will-need';
@import './components/tenancy-start-date';
@import './components/check-details';
@import './components/something-went-wrong';
@import './components/password-strength';

html,
body,
#root,
.c-app-registration,
.c-app-login,
.c-app-forgot-password {
  height: 100%;
}

h1 {
  &.app-page-title,
  &.create_login_title {
    font-size: 4rem !important;
    margin-bottom: 3rem;
    @media screen and (max-width: 480px) {
      font-size: 3.2rem !important;
    }
  }
}

h1,
h2 {
  font-size: 2rem !important;
}
h3 {
  &.page-info-title {
    font-size: 2rem !important;
  }
}
.c-heading--lg,
.c-heading--md,
.c-heading--sm,
.c-header__link {
  font-weight: 700 !important;
  @media screen and (max-width: 480px) {
    font-size: 1.4rem;
  }
}
.c-heading--sm {
  font-size: 2.4rem !important;
  line-height: 3.2rem !important;
}
.c-header__logo-link {
  width: 125px;
  height: 48px;

  .c-header__logo {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    width: 176px;
    height: 68px;
  }
}
.c-app-registration,
.c-app-login,
.c-app-forgot-password {
  #breadcrumbs {
    @media screen and (max-width: 480px) {
      font-size: 1.55rem;
    }
    @media screen and (min-width: 281px) and (max-width: 400px) {
      font-size: 1.38rem;
    }
    @media screen and (max-width: 360px) {
      font-size: 1.3rem;
    }
    @media screen and (max-width: 280px) {
      font-size: 0.98rem;
    }
    .no-spaces {
      gap: 0.5rem;
    }
    a {
      font-weight: 700 !important;
      min-width: fit-content;
    }
  }
}
.c-box {
  .c-content-group {
    .c-divider {
      color: #e3f3fd;
      margin: 0;
    }
  }
}
.c-app-registration,
.c-app-login,
.c-app-forgot-password {
  display: flex;
  flex-direction: column;
  .c-header {
    z-index: 1;
    margin-bottom: 0 !important;
    padding: 3rem 0;
    border-bottom: 0.3rem solid #ffffff;
    &.u-mb--400 {
      margin-bottom: 0 !important;
    }
    @media screen and (min-width: 992px) {
      min-height: auto;
    }
  }
}
.c-app {
  flex-grow: 1;
  min-height: auto !important;
  background-color: #ffffff !important;
  &.home-page {
    background-color: #f6f6f6 !important;
  }
}
.button-controls {
  &.c-form--cta {
    margin: 6.5rem 0 25.9rem !important;
  }
}
.c-button--default-outline {
  color: #131c42;
  border: 2px solid #131c42;
}
.c-button {
  font-family: 'Poppins', 'Arial', 'sans-serif' !important;
  font-size: 2rem !important;
  font-weight: 700;
  padding: 1rem 3.2rem !important;
  line-height: 3.2rem !important;
  &.c-button--link {
    padding: 0 !important;
  }
}
.c-button-row {
  margin-bottom: 3rem;
  button {
    &:first-child {
      margin-left: 0;
    }
  }
  .c-button {
    &:hover {
      &:not(:disabled) {
        background-color: #131c42;
        border-color: #131c42;
        color: #fff;
      }
    }
    @media screen and (max-width: 479px) {
      padding: 1rem !important;
      &:first-child {
        margin-left: 0;
      }
    }
    @media screen and (min-width: 480px) {
      margin-right: 7rem;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.c-content-group {
  .c-button--success {
    &:hover {
      &:not(:disabled) {
        background-color: #131c42;
        border-color: #131c42;
        color: #fff;
      }
    }
  }
}

.c-footer {
  min-height: 6.5rem;
}
.c-loader {
  position: fixed !important;
}
.c-form-row__label {
  .c-form-row__label-text {
    color: #131c42;
    font-weight: normal;
    margin: 0 0 1.1rem;
    line-height: 2.52rem;
  }
  .c-input {
    border: 0.05rem solid #000 !important;
    height: 4.6rem !important;
    font-size: 1.8rem !important;
    color: #131c42;
    padding-top: 1.1rem;
    padding-left: 1.3rem;
    padding-bottom: 1rem;
  }
}
.accordion-single-toggle {
  margin: 3rem 0 1rem !important;
}
.contact-information-wrapper {
  .c-message {
    .c-message__title {
      font-family: 'Poppins', 'Arial', 'sans-serif';
      font-size: 1.8rem;
      line-height: 2.7rem;
      margin-bottom: 0.936rem !important;
      margin-top: 0;
      font-weight: 500;
    }
  }
}
.no-padding-top {
  padding-top: 0 !important;
}
.no-underline {
  text-decoration: none;
}
.no-breadcrumb {
  padding-top: 4.8rem;
}
.text-center {
  text-align: center;
}
.c-accordion-single__children {
  width: 100%;
  .c-button--link {
    color: #2441e5 !important;
    font-size: inherit !important;
  }
}
.manual-entry-button {
  color: #2441e5 !important;
}
.c-button--link-inline {
  font-family: 'Poppins', Arial, sans-serif !important;
}

.c-accordion-single {
  .c-accordion-single__button {
    color: $ywDarkBlue !important;
    font-size: 1.6rem;
    font-weight: 600;
  }
}

.c-message {
  a {
    font-family: inherit;
  }
  ul {
    margin: 0.5rem 2rem 0;
  }
}
.c-app-registration,
.c-app-login,
.c-app-forgot-password {
  .c-form-row {
    margin: 0 0 3rem;
    padding: 0;
    &.has-error {
      background-color: #f6f6f6 !important;
      border-left: 0.8rem solid #de6e68 !important;
      padding: 3.2rem !important;
      .c-form-row__msg {
        color: #131c42 !important;
        font-size: 1.8rem !important;
        margin: 0 0 1.1rem !important;
      }
      @media screen and (max-width: 767px) {
        padding: 2.4rem 1.6rem !important;
      }
    }

    &#isAdditionalBillPayerRequired_form-row {
      .c-radio-tile {
        margin-bottom: 0 !important;
      }
    }
    .c-radio {
      font-weight: 400;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      &__checkmark {
        &:after {
          animation: fadeIn 400ms forwards ease-in-out 0ms;
          transform: scale(0);
          -webkit-transform: scale(0);
          background: $ywDigitalBlue;
        }
      }
    }
    .c-radio-tile {
      margin-bottom: 0;
      .c-radio {
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        .c-radio__label {
          font-size: 2rem;
        }
      }
      &.is-active {
        label {
          color: $ywDigitalBlue;
        }
        .c-radio {
          &__checkmark {
            &:after {
              background: $ywDigitalBlue !important;
              transform: scale(1) !important;
              -webkit-transform: scale(1) !important;
            }
          }
        }
      }
      &#landlord_wrapper {
        .c-radio {
          padding-bottom: 0;
        }
      }
    }
    &#occupancyStatus_form-row {
      .c-radio-tile {
        &__children {
          color: #707070;
          font-size: 80%;
        }
        &:last-child {
          .c-radio {
            padding-bottom: 0;
          }
        }
      }
    }
    &#accountReferenceNotKnown_form-row {
      margin: 0 0 6rem !important;
    }
    &#date-of-birth-day_form-row,
    #date-of-birth-month_form-row {
      margin-right: 2rem;
    }
    &#date-of-birth-day_form-row,
    #date-of-birth-month_form-row,
    #date-of-birth-year_form-row {
      .c-input {
        height: 4.6rem !important;
        text-align: center;
      }
    }
  }
}

.services-required,
.reasons-required {
  #servicesRequired_form-row,
  #reasonsRequired_form-row {
    margin-bottom: 1.4rem !important;
  }
  #servicesRequired_form-row_label {
    display: none;
  }
}
.reasons-required {
  .page-info-title {
    margin-bottom: 3rem !important;
  }
}

.page-header-info {
  font-size: 1.8rem;
  color: #000000;
}

.you-need-to-complete {
  font-size: 1.4rem;
  color: #707070;
}

.back-to-website-btn,
.back-to-account-btn {
  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.current-address,
.contact-details {
  .you-need-to-complete {
    margin-top: 3rem;
  }
  #dateOfBirth_form-row {
    margin-bottom: 2rem !important;
  }
}

#servicesRequired_form-row,
#reasonsRequired_form-row {
  margin-bottom: 0;
}

.reference-not-known-checkbox,
.terms-and-conditions-checkbox {
  #accountReferenceNotKnown_form-row,
  #checked_form-row {
    margin-bottom: 4rem;
    .c-checkbox {
      margin-top: 0;
      position: absolute;
      &__checkmark {
        height: 2.5rem;
        width: 2.5rem;
        top: 1rem;
      }
      &__input {
        &:checked {
          ~ .c-checkbox {
            &__checkmark {
              background: $ywDigitalBlue;
            }
          }
        }
      }
    }
  }
  .terms-and-conditions-container {
    display: flex !important;
    align-items: center;
  }
  p {
    // font-size: 1.4rem;
    margin: 1rem 0 0;
    padding-left: 4rem;
    a {
      color: #131c42;
      font-family: 'Arial', sans-serif;
    }
  }
}

.terms-and-conditions-checkbox {
  margin-bottom: 0 !important;
}

.reduired-field-indicator {
  font-size: 1.6rem;
  margin-top: 6rem !important;
  line-height: 1.968rem;
}

.no-margin {
  margin: 0 !important;
}

.hidden-label {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
}

.what-next {
  h3 {
    font-size: 2rem !important;
  }
  margin-bottom: 44.1rem;
}
.c-app-registration,
.c-app-login,
.c-app-forgot-password {
  a {
    font-weight: 700;
    font-family: 'Poppins', Arial, sans-serif;
  }
  .c-review-details {
    padding: 0;
    margin-bottom: 3rem;
    &--border-bottom {
      border-bottom: 0.1rem dotted #000;
    }
    .c-review-details__items {
      margin-bottom: 0;
      .c-review-details__item {
        display: block;
        margin-bottom: 3rem;
        .c-review-details__key {
          margin-bottom: 1.5rem;
        }
        .c-review-details__value {
          color: #2441e5;
          margin-bottom: 0;
        }
      }
    }
    .c-review-details__actions {
      margin-bottom: 3rem;
    }
  }
  .c-link--alt {
    font-family: 'Poppins', 'Arial', 'sans-serif';
    font-weight: 700;
    color: #2441e5;
  }
  #existingCustomer_form-row {
    margin-bottom: 0;
  }
  .login-form {
    #password_form-row,
    #confirmPassword_form-row {
      input {
        padding-right: 4.6rem;
        border-right: none !important;
      }
    }

    .password-wrapper {
      position: relative;
      #password {
        padding-right: 3.5rem;
      }
      .c-icon {
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
      }
      button {
        position: absolute;
        right: 10px;
        top: 47px;
        padding: 0 !important;
        min-height: 0 !important;
        border: none !important;
        line-height: 0 !important;
        background-color: unset !important;
        box-shadow: none !important;
      }
    }
    .password-peek-eye {
      width: 4.6rem;
      height: 4.6rem;
      border-top: 0.05rem solid #000 !important;
      border-right: 0.05rem solid #000 !important;
      border-bottom: 0.05rem solid #000 !important;
      display: flex;
      align-items: center;
      padding: 0 1.3rem;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      right: 7px;
      img {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    .has-error {
      .password-peek-eye {
        right: 40px;
      }
    }
  }
  .forgot-password-link {
    display: block;
    width: fit-content;
    margin-left: auto;
  }
}
