.password-strength {
  &__title {
    font-size: 2rem !important;
    font-weight: 700 !important;
  }
  &__bar {
    background-color: #d8d8d8;
    height: 5px;
    margin: 10px 0;
    position: relative;
    &::after {
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      transition: all ease-in 0.2s;
      [data-strength='1'] & {
        background-color: #de6e68;
        width: 30%;
      }
      [data-strength='2'] & {
        background-color: #eeb743;
        width: 65%;
      }
      [data-strength='3'] & {
        background-color: #eeb743;
        width: 65%;
      }
      [data-strength='4'] & {
        background-color: #50ad98;
        width: 100%;
      }
    }
  }
  &__message {
    font-size: 2rem !important;
    margin: 0;
  }
}
