.check-details {
  .anything-else {
    border-bottom: 1px dotted #000;
    strong {
      margin-bottom: 1.5rem;
    }
    #anythingElse_form-row {
      margin-bottom: 3rem !important;
    }
  }
  // .termsAndConditions {
  //   display: flex;
  //   align-items: center;
  //   margin-bottom: 5rem;
  //   #termsAccepted_form-row,
  //   #checked_form-row {
  //     margin-bottom: 4rem;
  //     .c-checkbox {
  //       margin-top: 0;
  //       &__checkmark {
  //         height: 2.5rem;
  //         width: 2.5rem;
  //         top: 10px;
  //         &::after {
  //           left: 0.7rem;
  //           top: 0.2rem;
  //         }
  //       }
  //     }
  //   }
  //   p {
  //     font-size: 1.4rem;
  //     margin-bottom: 0;
  //     a {
  //       color: #131c42;
  //       font-family: 'Arial', sans-serif;
  //     }
  //   }
  // }
  // .c-review-details {
  //   &__items {
  //     &:not(:last-of-type) {
  //       margin-bottom: 0;
  //     }
  //   }
  //   &__item {
  //     display: block;
  //     margin-bottom: 3rem;
  //   }
  //   &__value {
  //     padding-left: 0;
  //     color: $ywDigitalBlue;
  //   }
  //   &__list {
  //     margin-bottom: 0;
  //   }
  //   &__action {
  //     .c-button {
  //       color: #121c42;
  //       border-color: #121c42;
  //       padding: 1rem 1.5rem;
  //       &:hover {
  //         color: #131c42;
  //         border-color: #131c42;
  //       }
  //     }
  //   }
  //   &--border-bottom {
  //     border-bottom: 0.2rem dotted #d8d8d8;
  //     padding: 2rem 0 3rem;
  //   }
  //   &--border-top {
  //     border-top: 0.2rem dotted #d8d8d8;
  //     padding: 2rem 0 3rem;
  //   }
  //   @media screen and (max-width: 991px) {
  //     &__item {
  //       margin-bottom: 2rem;
  //     }
  //   }
  // }
  // .logged-in-contact-details {
  //   border-bottom: 0.2rem dotted #d8d8d8;
  //   padding-bottom: 4rem;
  //   margin-bottom: 3rem;
  //   .title {
  //     font-weight: 700;
  //     margin-bottom: 0.1rem;
  //   }
  // }
  // .bill-payer-message {
  //   border-bottom-style: dotted;
  //   border-bottom-color: #d8d8d8;
  //   border-bottom-width: 0.2rem;
  //   padding: 1rem 0 3rem;
  //   margin: 0;
  // }
  // .payment-option-title {
  //   display: block;
  //   padding: 2rem 0 0;
  // }
  // .selected-payment-option {
  //   margin-bottom: 2rem;
  // }
  // .directDebitImageWrapper {
  //   display: flex;
  //   align-items: center;
  //   flex-direction: column;
  //   background-color: #fff;
  //   width: fit-content;
  //   text-align: center;
  //   padding: 3rem;
  //   margin: 0.8rem auto 0.8rem 0;
  //   border: 1px solid #eee;
  //   p {
  //     padding: 0.8rem 0;
  //     margin-bottom: 0;
  //   }
  //   .ddOriginatorNumber {
  //     border: 1px solid #ccc;
  //     span {
  //       padding: 0.5rem;
  //       border-right: 1px solid #ccc;
  //       &:last-child {
  //         border-right: none;
  //       }
  //     }
  //   }
  // }
}
