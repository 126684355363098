.something-went-wrong {
  margin-bottom: 44.164em;
  h1 {
    font-size: 3rem !important;
  }
  .content-left-wrapper,
  .content-right-wrapper {
    margin-bottom: 3.4rem;
    margin-top: 10rem;
  }
  .content-right-wrapper {
    padding-left: 6rem;
  }
  .header-text {
    display: block;
    font-size: 4.8rem;
    line-height: 4.752rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 4.5rem;
  }
  @media screen and (max-width: 767px) {
    .header-text {
      font-size: 3.6rem;
      line-height: 3.564rem;
    }
    p {
      font-size: 1.6rem;
      line-height: 2.72rem;
      font-weight: 400;
    }
    .content-right-wrapper {
      padding-left: 0;
    }
  }
}
